import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

export default function PlaylistTrack(props) {
    let artists = props.track.artists.map((artist) => artist.name).join(", ")

    return (
        <ListItem button>
            <ListItemText primary={props.track.name} secondary={artists} />
        </ListItem>
    )
}