import React, { Component } from 'react';
import { Button, FormControl, FormGroup, TextField, Typography, Grid } from '@material-ui/core';
import Table from './components/Table';



export default class DisplayPlaylist extends Component {

    render() {
        /*let rows = this.props.tracksList.map(
            (track) => {
                let artists = track.track.artists.map((artist) => artist.name).join(", ")
                let tempo = track.audio_features ? parseInt(Math.round(track.audio_features.tempo)) : "-";
                return createData(track.track.name, artists, tempo, track.track.id)
            }
        )*/

        return (
            <div>
                <Grid container direction="row" style={{ marginBottom: 15 }} >
                    <Grid item md={5} style={{marginBottom: 15}}>
                        <Typography>Reorder or delete songs before exporting to Spotify</Typography>
                        <form  className="export-playlist-form">

                            <FormControl style={{width: "80%"}} >
                                <FormGroup style={{width: "100%"}}>
                                    <h3>Title</h3>
                                    <TextField style={{width: "100%"}} name="playlistTitle" onChange={this.props.handleTitleAndDescriptionChange} value={this.props.playlistTitle} />
                                </FormGroup>
                                <FormGroup style={{width: "100%"}}>
                                    <h4>Description</h4>
                                    <TextField style={{width: "100%"}} name="playlistDescription" onChange={this.props.handleTitleAndDescriptionChange} value={this.props.playlistDescription} />
                                </FormGroup>

                            </FormControl>
                        </form>
                        <Grid container direction="row" style={{marginTop: 25}}>
                            <Button style={{ marginRight: 10 }} disabled={this.props.tracksList.length === 0} variant="contained" color="primary" onClick={this.props.exportToSpotify}>Export to Spotify</Button>
                            <Button variant="contained" onClick={this.props.cancel}>Cancel</Button>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            {
                                this.props.tracksList.length > 0 ?
                                <Table
                                    sortSongs={this.props.sortSongs}
                                    undoDisabled={this.props.undoDisabled}
                                    rows={this.props.tracksList}
                                    handleDelete={this.props.handleDelete}
                                    undoDelete={this.props.undoDelete} />
                                :
                                <Typography>Sorry, no tracks in the specified tempo range were found in your selected playlists.</Typography>
                            }
                        </div>
                    </Grid>


                </Grid>


            </div>
        )
    }

}