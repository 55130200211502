import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { List, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PlaylistTrack from './components/PlaylistTrack'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Playlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            anchorEl: null,
            open: false,
            toDeleteName: "",
            dialogOpen: false
        }

        this.handleExpandClick = this.handleExpandClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.confirmClicked = this.confirmClicked.bind(this)
    }

    // can also change the cards to be clickable (ie, open it in spotify or something)
    // and add action buttons to the bottom
    // https://material-ui.com/components/cards/

    handleExpandClick = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: Boolean(event.currentTarget)
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false
        })
    };

    handleSelect = (ev, id, name) => {
        this.setState({
            dialogOpen: true,
            toDeleteName: name,
            toDeleteId: id
        })
        this.handleClose()
    }

    confirmClicked = (ev, confirm) => {
        if (confirm) {
            this.props.delete(this.state.toDeleteId)
        }
        this.setState({
            dialogOpen: false
        })
    }

    render() {
        let playlist = this.props.playlist;

        // can eventually use moreverticon to add actions for playlists (ie, delete or edit)
        /* can extend card actions with
        <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>

                    */
        const options = [
            "Unfollow Playlist"
        ]

        return (
            <Card variant="outlined" style={{ width: 320, margin: 10 }}>
                <Dialog
                    open={this.state.dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Unfollow Playlist"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to unfollow the playlist called <b>{this.state.toDeleteName}</b>?
                        </DialogContentText>
                        <DialogContentText>
                            <i>This action will delete the playlist from your Spotify library</i>
                        </DialogContentText>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" id="cancel" onClick={(e) => this.confirmClicked(e)}>
                            Cancel
                        </Button>
                        <Button color="primary" autoFocus id="confirm"  onClick={(e) => this.confirmClicked(e, true)}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <CardHeader
                    action={
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={this.state.open}
                                onClose={this.handleClose}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} onClick={(e) => this.handleSelect(e, playlist.id, playlist.name)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    }
                    title={playlist.name}
                />

                <CardMedia
                    style={{ height: 250 }}
                    image={playlist.images && playlist.images.length > 1 ? playlist.images[0].url : "playlistPlaceholder.png"}
                />
                {
                    this.props.tracks &&
                    <CardActions disableSpacing>
                        <Tooltip title={`Show ${this.props.tracks.length} tracks`}>
                            <IconButton
                                style={{ marginLeft: "auto", transform: `rotate(${this.state.expanded ? 180 : 0}deg)` }}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                    </CardActions>

                }
                {
                    this.props.tracks &&
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography variant="h6">
                                Tracks
                            </Typography>
                            <List>
                                {this.props.tracks.map((trackID) => <PlaylistTrack key={trackID} track={this.props.allTracks[trackID].track} />)}
                            </List>
                        </CardContent>
                    </Collapse>
                }

            </Card>

        )
    }
}