import React, { Component } from 'react';
import Playlists from './components/Playlists';
import ProfileData from './components/ProfileData';


export default class Profile extends Component {
    render() {
        return (
            <div className="profile">
                <ProfileData profileData={this.props.profileData}/>
                <Playlists delete={this.props.delete} tracks={this.props.tracks} playlistTracks={this.props.playlistTracks} playlists={this.props.playlists}/>
            </div>
        )
    }
}