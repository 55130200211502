import React, { Component } from 'react';
import ReactLoading from 'react-loading';


export default class Loading extends Component {
    render() {
        return (
            <div>
                <ReactLoading 
                    type={"bubbles"}
                    color={"green"}
                />
            </div>
        )
    }
}