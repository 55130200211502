import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Profile from './Profile'
import CreatePlaylist from './CreatePlaylist'
import cookie from 'react-cookies'
import fetchRetry from '../services/FetchRetry'
import { AppBar, Toolbar, Typography } from '@material-ui/core';


let API_ENDPOINT = 'https://api.spotify.com/v1/'

const hash = window.location.hash
    .substring(1)
    .split("&")
    .reduce(function (initial, item) {
        if (item) {
            let parts = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
    }, {});

window.location.hash = "";

export default class Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            profileData: null,
            playlists: null,
            processing: 0,
            tracksList: [],
            tracksListHistory: [],
            playlistTitle: "",
            playlistDescription: "",
            loadedPlaylists: false, // used to determine if we have gotten all audio-features for all tracks for all playlist
            playlistTracks: {}, // contains list of trackIDs for playlists, can potentially cache this variable,
            screen: 0
        }

        this.data = {};
        this.tempos = {};
        this.tracks = {};

        for (let i = 0; i < 300; i++) {
            this.tempos[i] = [];
        }

        this.loginClicked = this.loginClicked.bind(this);
        this.logoutClicked = this.logoutClicked.bind(this);
        this.getProfileData = this.getProfileData.bind(this);
        this.getPlaylists = this.getPlaylists.bind(this);
        this.dataCaller = this.dataCaller.bind(this);
        this.login = this.login.bind(this);
        this.createPlaylistClicked = this.createPlaylistClicked.bind(this);
        this.getAudioFeatures = this.getAudioFeatures.bind(this);
        this.createPlaylistFinished = this.createPlaylistFinished.bind(this);
        this.exportToSpotify = this.exportToSpotify.bind(this);
        this.handleTitleAndDescriptionChange = this.handleTitleAndDescriptionChange.bind(this);
        this.getPlaylistTracksOutdated = this.getPlaylistTracksOutdated.bind(this);
        this.getPlaylistsTracks = this.getPlaylistsTracks.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.undoDelete = this.undoDelete.bind(this);
        this.exportToSpotifyHelper = this.exportToSpotifyHelper.bind(this)
        this.getPlaylistsHelper = this.getPlaylistsHelper.bind(this)
        this.deletePlaylistClicked = this.deletePlaylistClicked.bind(this)
        this.sortSongs = this.sortSongs.bind(this)
    }

    getPlaylistsHelper(playlists, apiCall) {
        return new Promise((resolve, reject) => {
            fetchRetry(apiCall, {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                }
            }, this.logoutClicked)
                .then((res) => {
                    //console.log("getPlaylistsHelper res: ", res)
                    playlists = playlists.concat(res.items)
                    if (res.next != null) {
                        this.getPlaylistsHelper(playlists, res.next).then((peel) => resolve(peel));
                    }
                    else {
                        resolve(playlists);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        })
    }

    getPlaylists(callback = () => { }) {
        if (this.state.token) {
            this.getPlaylistsHelper([], `${API_ENDPOINT}me/playlists?limit=50`).then((res) => {
                //console.log("getPlaylists res: ", res)
                this.setState({
                    playlists: res
                }, callback)
            }
            )
        }
    }

    getProfileData(callback = () => { }) {
        if (this.state.token) {
            fetchRetry(`${API_ENDPOINT}me`, {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                }
            }, this.logoutClicked)
                .then((res) => {

                    this.setState({
                        profileData: res
                    }, callback)
                })
                .catch((err) => {
                    console.log(err);
                })


        }
    }

    dataCaller() {
        this.getProfileData();
        let optionsTrojanHorse = {
            selectedPlaylists: 25
        }
        this.getPlaylists(() => this.getPlaylistsTracks(optionsTrojanHorse));
    }

    createPlaylistFinished(options) {
        /*cookie.save("playlistData", JSON.stringify(this.data));
        cookie.save("temposData", JSON.stringify(this.tempos));*/

        //console.log("options ", options)
        //console.log("done")
        //console.log(this.tempos);

        let idsList = [];
        let allowedTracks = new Set();
        let numPlaylists = 0;
        let topTempo = options.bpm + options.range;
        let botTempo = options.bpm - options.range;
        let title = botTempo === topTempo ? `${botTempo} BPM` : `${botTempo} - ${topTempo} BPM`
        let description = "Playlist created by BPM Buddy"

        if (options.selectedPlaylists === -1) {
            numPlaylists = this.state.playlists.length
        }
        else {
            numPlaylists = options.selectedPlaylists;
        }

        for (let i = 0; i < Math.min(this.state.playlists.length, numPlaylists); i++) {
            if (this.state.playlistTracks[this.state.playlists[i].id]) {
                for (let j = 0, z = this.state.playlistTracks[this.state.playlists[i].id]; j < z.length; j++) {
                    allowedTracks.add(z[j])
                }
            }


        }

        // for max number of songs
        // note:  if you put too high of a range, and a small max number of songs, the playlist will only have tempos of the home tempo
        // ie, percentage for each tempo is too low (<1%), gets floored to be 0 total songs from that tempo
        let tempos = {};
        for (let i = botTempo; i <= topTempo; i++) {
            tempos[i] = this.tempos[i].filter(song => allowedTracks.has(song))
        }

        if (options.maxSongs) {

            let totalSongsInRange = 0;
            let percentages = {};

            for (let i = botTempo; i <= topTempo; i++) {
                totalSongsInRange += tempos[i].length;
            }

            for (let i = botTempo; i <= topTempo; i++) {
                percentages[i] = tempos[i].length / totalSongsInRange;
            }
            let sum = 0;

            for (let i = botTempo; i <= topTempo; i++) {
                idsList.push(tempos[i].slice(0, Math.floor(percentages[i] * options.maxSongs)));
                sum += Math.floor(percentages[i] * options.maxSongs);
            }

            idsList[options.range] = idsList[options.range].concat(tempos[options.bpm].slice(Math.floor(percentages[options.bpm] * options.maxSongs), Math.floor(percentages[options.bpm] * options.maxSongs) + options.maxSongs - sum));
        } else {
            for (let i = botTempo; i <= topTempo; i++) {
                idsList.push(tempos[i]);
            }
        }

        idsList = idsList.flat();

        // we need to keep this because
        // for now, adding the extra songs from the home tempo can add duplicates if there are not enough songs processed
        idsList = new Set(idsList)
        idsList = Array.from(idsList);

        let tracksList = idsList.map((id) => this.tracks[id])

        let rows = tracksList.map(
            (track) => {
                let artists = track.track.artists.map((artist) => artist.name).join(", ")
                let tempo = track.audio_features ? parseInt(Math.round(track.audio_features.tempo)) : "-";
                let cData = {
                    name: track.track.name, 
                    artist: artists, 
                    bpm: tempo, 
                    trackId: track.track.id,
                    uri: track.track.uri
                }
                return cData
            }
        )

        this.setState({
            processing: 2,
            tracksList: rows,
            playlistTitle: title,
            playlistDescription: description
        })
        //display      

    }

    getPlaylistsTracks(options, callback = () => { }) {
        // dont u dare touchthis line
        console.log("filly");

        let fetches = [];
        this.playlistTracks = {};
        if (options.selectedPlaylists === -1) {
            options.selectedPlaylists = this.state.playlists.length;
        }

        for (let i = 0; i < Math.min(this.state.playlists.length, options.selectedPlaylists); i++) { //deal with start/stop values
            let oneId = this.state.playlists[i].id

            if (!(this.data[oneId])) {
                this.data[oneId] = []
                this.playlistTracks[oneId] = []
                fetches.push(this.getAudioFeatures(`${API_ENDPOINT}playlists/${this.state.playlists[i].id}/tracks`, oneId, this.state.token));
            }

        }

        Promise.allSettled(fetches).then(() => {

            // remove duplicates in this.tempos while preserving order
            for (let tempo of Object.keys(this.tempos)) {
                this.tempos[tempo] = this.tempos[tempo].filter((id, index) => {
                    return this.tempos[tempo].indexOf(id) === index;
                })
            }
            //console.log(this.data)
            //.log(this.tempos)
            //.log("done getting playlists")

            this.setState({
                playlistTracks: { ...this.state.playlistTracks, ...this.playlistTracks },
                loadedPlaylists: true
            }, callback(options))
        });
    }

    //this.state.tracksList has to change

    exportToSpotifyHelper(first, res) {
        let subTracksList = this.state.tracksList.slice(first, Math.max(first + 100, this.state.tracksList.length));

        fetchRetry(`${API_ENDPOINT}playlists/${res.id}/tracks`, {
            headers: {
                'Authorization': "Bearer " + this.state.token,
                'Content-Type': "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                uris: subTracksList.map((track) => track.uri),
            })
        })
            .then(() => {
                //console.log("100 songs added");
                if (this.state.tracksList.length > first + 100) {
                    this.exportToSpotifyHelper(first + 100, res);
                }
            })
    }

    sortSongs(rows) {
        this.setState({
            tracksList: rows
        })
    }

    exportToSpotify() {
        
        if (this.state.token) {
            fetchRetry(`${API_ENDPOINT}users/${this.state.profileData.id}/playlists/`, {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                },
                method: "POST",
                body: JSON.stringify({
                    name: this.state.playlistTitle,
                    public: true,
                    collaborative: false,
                    description: this.state.playlistDescription,
                })
            }, this.logoutClicked)
                .then((res) => {
                    let playlistId = res.id
                    this.getPlaylists(() => {
                        let {playlistTracks, tracksList} = this.state
                        playlistTracks[playlistId] = tracksList.map((track) => track.trackId)
                        this.setState({
                            playlistTracks
                        })
                    })

                    this.exportToSpotifyHelper(0, res);

                    this.setState({
                        processing: 0
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    createPlaylistClicked(options) {
        this.setState({
            processing: 1
        })
        this.getPlaylistsTracks(options, this.createPlaylistFinished);

        return;
    }

    deletePlaylistClicked(playlistId) {
        //console.log("delete", playlistId)

        if (this.state.token) {
            fetchRetry(`${API_ENDPOINT}playlists/${playlistId}/followers`, {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                },
                method: "DELETE"
            }, this.logoutClicked)
                .then(() => {
                    //console.log("deleted")
                    this.getPlaylists()
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    getAudioFeatures(url, oneId, token) {
        return new Promise((resolve, reject) => {
            if (token) {
                fetchRetry(url, { //returns promise. currenlty i have nothing but I pinky promise i'll eventually give you ana nswer
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                }, this.logoutClicked)
                    .then((res) => {
                        let tracks;
                        let trackIDs;
                        let audioAnal;
                        tracks = res.items;
                        trackIDs = tracks.map((item) => item.track.id);
                        if (trackIDs.length > 0) {
                            for (let j = 0; j < trackIDs.length; j++) {
                                this.tracks[tracks[j].track.id] = tracks[j];
                            }

                            this.playlistTracks[oneId] = this.playlistTracks[oneId].concat(tracks.map((item) => item.track.id))
                            this.setState({
                                playlistTracks: {
                                    ...this.state.playlistTracks,
                                    [oneId]: this.playlistTracks[oneId]
                                },
                            })


                            if (res.next != null) {
                                this.getAudioFeatures(res.next, oneId, token)
                            }
                            fetchRetry(`${API_ENDPOINT}audio-features?ids=${trackIDs.join()}`, {
                                headers: {
                                    'Authorization': "Bearer " + token,
                                }
                            }, this.logoutClicked)
                                .then((res) => {
                                    //console.log("finished audio-features for ", tracks[0].track.id)
                                    audioAnal = res;
                                    if (!audioAnal) {
                                        resolve()
                                        return
                                    }
                                    for (let j = 0; j < Math.min(trackIDs.length, 100); j++) {
                                        if (!audioAnal.audio_features[j]) continue
                                        tracks[j].audio_features = audioAnal.audio_features[j];
                                        let tempo = Math.round(audioAnal.audio_features[j].tempo);
                                        this.tempos[tempo].push(tracks[j].track.id);
                                        this.tracks[tracks[j].track.id] = tracks[j];
                                    }
                                    this.data[oneId] = this.data[oneId].concat(tracks); //change

                                    resolve()
                                })
                                .catch((err) => {
                                    console.log(err)
                                    reject(err)
                                })
                        } else {
                            resolve()
                            return;
                        }

                    })
                    .catch((err) => {
                        reject(err);
                    })
            }

        })
    }

    login(callback) {
        let spotifyAccessToken = cookie.load("spotifyAccessToken")
        if (spotifyAccessToken) {
            this.setState({
                token: spotifyAccessToken,
                loggedIn: true
            }, callback);
        } else {
            let _token = hash.access_token;
            if (_token) {
                cookie.save("spotifyAccessToken", _token, { path: "/" })
                this.setState({
                    token: _token,
                    loggedIn: true
                }, callback);
            }
        }
    }

    handleTitleAndDescriptionChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleDelete(selected) {
        //console.log(selected);
        //console.log(this.state.tracksList);
        let indices = [];
        let saveHistory = JSON.parse(JSON.stringify(this.state.tracksListHistory));
        saveHistory.push(this.state.tracksList);

        for (let i = 0; i < this.state.tracksList.length; i++) {
            if (selected.indexOf(this.state.tracksList[i].trackId) !== -1) {
                indices.push(i);
            }
        }
        //console.log(indices)

        indices.sort();
        indices.reverse();
        let newTracksList = JSON.parse(JSON.stringify(this.state.tracksList))

        for (let i of indices) {
            newTracksList.splice(i, 1);
        }

        //console.log(newTracksList);

        this.setState({
            tracksListHistory: saveHistory,
            tracksList: newTracksList
        })
    }

    undoDelete() {
        //console.log("in undoDelete");
        if (this.state.tracksListHistory.length === 0) {
            return;
        }
        //console.log("in undoDelete2");
        let history = JSON.parse(JSON.stringify(this.state.tracksListHistory))

        let oldTracksList = history.pop()
        this.setState({
            tracksList: oldTracksList,
            tracksListHistory: history
        })
    }

    cancel() {
        //console.log("filliam")
        this.setState({
            processing: 0
        })
    }

    componentDidMount() {
        this.login(this.dataCaller)
    }

    loginClicked() {
        let authEndpoint = 'https://accounts.spotify.com/authorize';
        let redirectUri = process.env.REACT_APP_REDIRECT_URI;
        // will probably have to add more scopes to this array
        let scopes = ['user-read-private', 'user-read-email', 'playlist-modify-private', 'user-library-read', 'playlist-read-private', 'playlist-read-collaborative', 'playlist-modify-public'];
        window.location.href = `${authEndpoint}?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`
    }

    logoutClicked() {
        cookie.remove("spotifyAccessToken")
        localStorage.clear()
        this.setState({
            token: null,
            loggedIn: false
        })
    }

    changeScreen(num) {
        this.setState({
            screen: num
        })
    }

    render() {
        if (this.state.loggedIn) {
            return (
                <div>
                    <AppBar position="static">
                        <Toolbar>
                        <Typography variant="h6" style={{flexGrow: 1}}>
                            BPM Buddy
                        </Typography>
                            <Button color="inherit" onClick={() => this.changeScreen(0)}>Profile</Button>
                            <Button color="inherit" onClick={() => this.changeScreen(1)}>Create Playlist</Button>
                            <Button onClick={this.logoutClicked} style={{marginLeft: 0}} color="inherit">Log Out</Button>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding: "2%"}}>
                        {
                            this.state.screen === 0 &&
                            <Profile
                                profileData={this.state.profileData}
                                playlists={this.state.playlists}
                                tracks={this.tracks}
                                playlistTracks={this.state.playlistTracks}
                                delete={this.deletePlaylistClicked}
                            />
                        }
                        {
                            this.state.screen === 1 &&
                            <CreatePlaylist
                                tracksList={this.state.tracksList}
                                processing={this.state.processing}
                                submit={this.createPlaylistClicked}
                                playlistTitle={this.state.playlistTitle}
                                playlistDescription={this.state.playlistDescription}
                                exportToSpotify={this.exportToSpotify}
                                handleTitleAndDescriptionChange={this.handleTitleAndDescriptionChange}
                                loadedPlaylists={this.state.loadedPlaylists}
                                handleDelete={this.handleDelete}
                                cancel={this.cancel}
                                undoDelete={this.undoDelete}
                                undoDisabled={this.state.tracksListHistory.length === 0}
                                sortSongs={this.sortSongs}
                            />
                        }                    
                    </div>

                </div>
            )
        } else {
            return (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}>
                    <h1>BPM Buddy</h1>
                    <h4>Your personal playlist creator</h4>
                    <Button 
                        style={{
                            marginTop: 20,
                            marginBottom: 30
                        }}
                        onClick={this.loginClicked} 
                        variant="contained" 
                        color="primary"
                    >Log in with Spotify</Button>
                    <br/>
                    <p>Created by Alex Chao and Finley Lau</p>
                </div>
                            )
        }
    }

    getPlaylistTracksOutdated() {

        //don't u dare touch this line.
        console.log("filly");

        this.data = {};
        this.tempos = {};
        this.tracks = {};
        let fetches = [];

        for (let i = 0; i < 300; i++) {
            this.tempos[i] = [];
        }

        this.playlistTracks = {}

        for (let i = 0; i < this.state.playlists.length; i++) {
            let oneId = this.state.playlists[i].id


            this.data[oneId] = []
            this.playlistTracks[oneId] = []

            fetches.push(this.getAudioFeatures(`${API_ENDPOINT}playlists/${this.state.playlists[i].id}/tracks`, oneId, this.state.token));
        }


        Promise.allSettled(fetches).then(() => {
            //localStorage.setItem('playlistData', JSON.stringify(this.data))
            //localStorage.setItem('tempoData', JSON.stringify(this.tempos))

            // remove duplicates in this.tempos while preserving order
            for (let tempo of Object.keys(this.tempos)) {
                this.tempos[tempo] = this.tempos[tempo].filter((id, index) => {
                    return this.tempos[tempo].indexOf(id) === index;
                })
            }

            //console.log("done getting playlists")
            //console.log(Object.keys(this.tracks).length)
            //console.log(this.tempos)
            this.setState({
                playlistTracks: this.playlistTracks,
                loadedPlaylists: true
            })
        });
    }
}