import './App.css';
import Content from './components/Content'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1DB954",
    },
    secondary: {
      main: "#191414",
    }
  },
});


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Content />
      </ThemeProvider>
    </div>
  );
}

export default App;
