export default function fetchRetry(url, options = {}, logout = () => { }, retries = 5, backoff = 2000) {
  const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524]
  const logoutCodes = [401]
  return fetch(url, options)
    .then(res => {
      if (res.ok) return res.json()
      if (logoutCodes.includes(res.status)) {
        logout()
      }
      if (retries > 0 && retryCodes.includes(res.status)) {
        let newBackoff = backoff
        if (res.headers.has("Retry-After")) {
          newBackoff = parseInt(res.headers.get("Retry-After")) * 1000
        }

        return delay(newBackoff).then(() => {
          return fetchRetry(url, options, retries - 1)
        })
        
      } else {
        throw new Error(res)
      }
    })
    .catch((err) => console.log(err))
}

function delay(t, v) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t)
  });
}