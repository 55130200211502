import React, { Component } from 'react';
import { Grid, Button, InputLabel, Checkbox, Select, MenuItem, Typography, FormControlLabel, TextField, Tooltip } from '@material-ui/core';


export default class CreatePlaylistInfo extends Component {

    render() {
        return (
            <div className="create-playlist">
                <Grid container direction="row">

                    <Grid item md={6} style={{marginBottom: 15, width: "100%"}}>

                            <Grid container item direction="row" spacing={3} style={{marginBottom: 15, width: "100%"}} >
                                <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                                    <Typography>Choose BPM</Typography>
                                </Grid>
                                <Grid item md={6} >
                                <InputLabel>Target BPM</InputLabel>

                                    <TextField style={{width: "60%"}} disabled={!this.props.bpmEnabled} onChange={this.props.handleChange} type="number"  name="bpm" value={this.props.bpm} />
                                </Grid>
                            </Grid>
                            {false && <FormControlLabel
                                control={<Checkbox checked={this.props.bpmEnabled} onChange={this.props.handleCheckChange} name="bpmEnabled" />}
                                label={"Target BPM"}
                            />}
                            <Grid container item direction="row" spacing={3} style={{marginBottom: 15, width: "100%"}} >
                                <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.rangeEnabled} onChange={this.props.handleCheckChange} name="rangeEnabled" />}
                                        label={"+- Range"}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                <InputLabel>+- Range</InputLabel>

                                    <TextField style={{width: "60%"}} disabled={!this.props.rangeEnabled} onChange={this.props.handleChange} type="number" name="range" value={this.props.range} />
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={3} style={{marginBottom: 15, width: "100%"}} >
                                <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.maxEnabled} onChange={this.props.handleCheckChange} name="maxEnabled" />}
                                        label={"Max number of songs"}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                <InputLabel>Max number of songs</InputLabel>
                                    <TextField  style={{width: "60%"}} disabled={!this.props.maxEnabled} onChange={this.props.handleChange} type="number" name="maxSongs" value={this.props.maxSongs} />
                                </Grid>
                            </Grid>


                            <Grid container item direction="row" spacing={5} style={{marginBottom: 15, width: "100%"}} >
                                <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                                    <Typography>Choose Playlists</Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <InputLabel>Select from</InputLabel>
                                    <Select
                                        name="selectPlaylists"
                                        value={this.props.selectPlaylistsVal}
                                        onChange={this.props.handleSelectChange}
                                        style={{width: "60%"}}
                                    >
                                        <MenuItem value={10}>Recent 10</MenuItem>
                                        <MenuItem value={25}>Recent 25</MenuItem>
                                        <MenuItem value={50}>Recent 50</MenuItem>
                                        <MenuItem value={100}>Recent 100</MenuItem>
                                        <MenuItem value={-1}>All</MenuItem>

                                    </Select>
                                </Grid>
                            </Grid>

                        <Tooltip title={this.props.loadedPlaylists ? "Click to create a playlist" : "Loading track data..."}>
                            <span>
                                <Button disabled={!this.props.loadedPlaylists || this.props.processing} onClick={this.props.submit} variant="contained" color="primary">Create Playlist</Button>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item md={6} >
                        <div className="create-playlist-directions">
                            <h3>Directions</h3>
                            <p>Step 1: Select a target BPM for your playlist to be centered around</p>
                            <p>Step 2. Select a range of tempos to pull songs from (100 BPM +- 2 would give you songs from 98-102 BPM)</p>
                            <p>Step 3. Determine a value for the maximum number of songs to be in the playlist</p>
                            <p>Step 4. Select a range of your Spotify playlists to pull songs from</p>
                            <p>Step 5. Create your playlist!</p>
                        </div>

                    </Grid>
                </Grid>

            </div>
        )
    }
}