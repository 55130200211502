import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import Playlist from './components/Playlist';
import Loading from '../../../Loading';

export default class Playlists extends Component {

    render() {
        if (!this.props.playlists)
        {
            return <Loading/>
        }

        const listItems = this.props.playlists.map((playlist) => <Playlist delete={this.props.delete} key={playlist.id} playlist={playlist} allTracks={this.props.tracks} tracks={this.props.playlistTracks && this.props.playlistTracks[playlist.id]} />);

        return (
            <div>
                <h2>Playlists</h2>
                <Grid container direction="row" className="playlists-list">{listItems}</Grid>
            </div>
        )

    }

}