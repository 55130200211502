import React, { Component } from 'react';
import CreatePlaylistInfo from './components/CreatePlaylistInfo';
import Loading from '../Loading';
import DisplayPlaylist from './components/DisplayPlaylist';

// could offer choices of
// from playlist(s) ____
// from genre(s) _____
// etc.

export default class CreatePlaylist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bpmEnabled: true,
            rangeEnabled: false,
            bpm: 120,
            range: 0,
            maxEnabled: false,
            maxSongs: 10,
            selectPlaylists: 25,
        }

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.exportToSpotify = this.exportToSpotify.bind(this)
    }


    handleCheckChange(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.checked
        })
    }

    handleChange(e) {
        if(e.target.name === "bpm")
        {
            e.target.value = parseInt(Math.round(e.target.value));
            if(e.target.value < 0)
            {
                e.target.value = 1;
            }
            else if(e.target.value > 300)
            {
                e.target.value = 300;
            }
        }
        else if(e.target.name === "range")
        {
            e.target.value = parseInt(Math.round(e.target.value));
            if(e.target.value < 0)
            {
                e.target.value = 0;
            }
            else if(e.target.value > this.state.bpm)
            {
                e.target.value = this.state.bpm;
            }
        } else if (e.target.name === "maxSongs") {
            e.target.value = parseInt(Math.round(e.target.value));
            if(e.target.value < 0)
            {
                e.target.value = 1;
            }
        } 


        //fTODO ffilly ffind fout fwhy fthis fis fwrong
        this.setState({
            [e.target.name]: parseInt(e.target.value)
        })
    }

    exportToSpotify() {
        this.setState({
            bpmEnabled: true,
            rangeEnabled: false,
            bpm: 120,
            range: 0,
            maxEnabled: false,
            maxSongs: 10,
            selectPlaylists: 25,
        })

        this.props.exportToSpotify()
    }

    handleSelectChange(e) {
        this.setState({
            selectPlaylists: e.target.value
        })
    }

    submit() {

        let options = {bpm: this.state.bpm, range: this.state.range, selectedPlaylists: this.state.selectPlaylists, maxSongs: this.state.maxEnabled ? this.state.maxSongs : null};
        //console.log(options)
        this.props.submit(options);
    }



    render() {
        if(this.props.processing === 0) {
            return (
                <div>
                    <h2>Create Playlist</h2>
                    <CreatePlaylistInfo 
                        bpmEnabled = {this.state.bpmEnabled}
                        bpm = {this.state.bpm}
                        rangeEnabled = {this.state.rangeEnabled}
                        range = {this.state.range}
                        submit = {this.submit}
                        handleChange = {this.handleChange}
                        handleCheckChange = {this.handleCheckChange}
                        processing = {this.props.processing !== 0}
                        loadedPlaylists = {this.props.loadedPlaylists}
                        maxEnabled= {this.state.maxEnabled}
                        maxSongs = {this.state.maxSongs}
                        selectPlaylistsVal = {this.state.selectPlaylists}
                        handleSelectChange = {this.handleSelectChange}
                    />
                </div>
            )
        }
        else if(this.props.processing === 1)
        {
            return (
                <div>
                    <h2>Create Playlist</h2>
                    <Loading/>
                </div>
            )
        }
        else if(this.props.processing === 2)
        {
            return (
                <div>
                    <h2>Review Playlist</h2>
                    <DisplayPlaylist 
                        playlistTitle={this.props.playlistTitle}
                        playlistDescription={this.props.playlistDescription}
                        tracksList={this.props.tracksList}
                        exportToSpotify={this.exportToSpotify}
                        handleTitleAndDescriptionChange={this.props.handleTitleAndDescriptionChange}
                        handleDelete={this.props.handleDelete}
                        cancel={this.props.cancel}
                        undoDelete={this.props.undoDelete}
                        undoDisabled={this.props.undoDisabled} 
                        sortSongs={this.props.sortSongs}
                    />
                </div>
            )
        }
    }
}