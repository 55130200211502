import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import Loading from '../../../Loading'

export default class ProfileData extends Component {

    render() {
        if(!this.props.profileData) {
            return <Loading/>
        }

        var imgExists = this.props.profileData.images.length > 0;

        return (
            <div>
                <h2>Profile</h2>
                <Grid container direction="row">
                    {imgExists &&
                        <div className="profile-image">
                            <img alt="" src={this.props.profileData.images[0].url} />
                        </div>
                    }
                    <div className="profile-data">
                        <h3>{this.props.profileData.display_name}</h3>
                        <p>{this.props.profileData.email}</p>
                        <p>Number of followers: {this.props.profileData.followers.total}</p>
                        <Button onClick={() => window.open(this.props.profileData.external_urls.spotify, "_blank")} variant="contained" color="primary" >Open Spotify</Button>
                    </div>
                </Grid>
            </div>


        )
    }

}